"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _reverse = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reverse"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _lodash = _interopRequireDefault(require("lodash"));

/*
import toHtml from '@/libs/renderers/Html';
import parseMessage from '@/libs/MessageParser';
*/
var _default = {
  props: ['network'],
  data: function data() {
    this.maybeUpdateList();
    return {
      sidebarOpen: false,
      page: 0,
      page_size: 200,
      search: '',
      last_updated: 0,
      genderm: true,
      genderf: true,
      gendero: true,
      age: 0
    };
  },
  computed: {
    noResults: function noResults() {
      return this.listState === '' && this.filteredList.length === 0;
    },
    isLoading: function isLoading() {
      return this.listState === 'chargement';
    },
    listState: function listState() {
      return this.network.user_list_state;
    },
    list: function list() {
      return this.network.user_list || [];
    },
    filteredList: function filteredList() {
      var _context,
          _this = this,
          _context3;

      var list = [];
      list = (0, _filter.default)(_context = this.list).call(_context, function (user) {
        var _context2;

        var found = false;

        if (_this.genderm && (user.gender === 'M' || user.gender === 'm' || user.gender === 'h' || user.gender === 'H')) {
          found = true;
        }

        if (_this.genderf && (user.gender === 'F' || user.gender === 'f')) {
          found = true;
        }

        if (_this.gendero && user.gender === 'U') {
          found = true;
        }

        if ((0, _indexOf.default)(_context2 = user.nick.toLowerCase()).call(_context2, _this.search.toLowerCase()) > -1 && _this.search.length > 2 && found) {
          found = true;
        } else if (_this.search.length > 2) {
          found = false;
        }

        if (_this.age === '1' && user.age < 25 || _this.age === '2' && user.age >= 25 && user.age < 45 || _this.age === '3' && user.age >= 45) {
          found = true;
        } else if (_this.age !== 0) {
          found = false;
        }

        return found;
      });
      return (0, _reverse.default)(_context3 = _lodash.default.sortBy(list, 'nick')).call(_context3);
    },
    paginated: function paginated() {
      var offset = this.page * this.page_size;
      var list = this.filteredList;
      var channels = [];

      for (var i = offset; i < offset + this.page_size; i++) {
        if (list[i]) {
          channels.push(list[i]);
        }
      }

      return channels;
    },
    maxPages: function maxPages() {
      return Math.floor(this.filteredList.length / this.page_size);
    },
    canGoForward: function canGoForward() {
      return this.page * this.page_size >= this.filteredList.length;
    },
    canGoBackward: function canGoBackward() {
      return this.page > 0;
    }
  },
  watch: {
    search: function search() {
      this.page = 0;
    }
  },
  methods: {
    nextPage: function nextPage() {
      if (this.page < this.maxPages) {
        this.page++;
      }
    },
    prevPage: function prevPage() {
      if (this.page > 0) {
        this.page--;
      }
    },
    maybeUpdateList: function maybeUpdateList() {
      if (this.listState !== 'updating') {
        this.network.ircClient.raw('WHO *');
      }
    },
    joinChannel: function joinChannel(channelName) {
      this.$state.addBuffer(this.network.id, channelName);
      this.network.ircClient.join(channelName);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UserList"]
window._kiwi_exports.components.UserList = exports.default ? exports.default : exports;
