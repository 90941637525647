"use strict";
'kiwi public';
/** @module */

/**
 * Adds the +remojis IRCv3 spec to irc-framework
 */

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = remojisMiddleware;

function remojisMiddleware() {
  return function middleware(client, rawEvents, parsedEvents) {
    addFunctionsToClient(client);
    rawEvents.use(theMiddleware);
  };

  function theMiddleware(command, message, rawLine, client, next) {
    if (!(command === 'TAGMSG' && message.tags['+remojis/action'])) {
      next();
      return;
    } // if we are params[0] then the target is the sender (direct message)


    var target = message.params[0].toLowerCase() === client.user.nick.toLowerCase() ? message.nick : message.params[0]; // if its a privmsg without typing tag emit done

    var status = message.tags['+remojis/action'] || 'done';
    client.emit('addingR', {
      id: message.tags['+remojis/msgid'],
      emoji: message.tags['+remojis/emoji'],
      target: target,
      nick: message.nick,
      ident: message.ident,
      hostname: message.hostname,
      status: status
    });
    next();
  }
}

function addFunctionsToClient(client) {
  var addingR = client.addingR = {};

  function isEnabled() {
    return client.network.cap.isEnabled('message-tags') && client.network.cap.isEnabled('echo-message');
  }

  addingR.add = function start(target, msgid, emoji) {
    if (!isEnabled()) {
      return;
    }

    var message = new client.Message('TAGMSG', target);
    message.tags['+remojis/action'] = 'add';
    message.tags['+remojis/emoji'] = emoji;
    message.tags['+remojis/msgid'] = msgid;
    client.raw(message);
  };

  addingR.remove = function start(target, msgid, emoji) {
    if (!isEnabled()) {
      return;
    }

    var message = new client.Message('TAGMSG', target);
    message.tags['+remojis/action'] = 'remove';
    message.tags['+remojis/emoji'] = emoji;
    message.tags['+remojis/msgid'] = msgid;
    client.raw(message);
  };
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["RemojisMiddleware"]
window._kiwi_exports.libs.RemojisMiddleware = exports.default ? exports.default : exports;
