"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var Vue = require('vue');

var _default = Vue.component('gamechannel', {
  components: {},
  props: ['buffer', 'network'],
  computed: {},
  methods: {
    toggleInPage: function toggleInPage() {
      if (this.buffer.getGameAlwaysVisible()) {
        this.buffer.setGameAlwaysVisible(false);
      } else {
        this.buffer.setGameAlwaysVisible(true);
      }
    },
    joinChannel: function joinChannel(channelName) {
      this.$state.addBuffer(this.network.id, channelName);
      this.network.ircClient.join(channelName);
      this.$state.setActiveBuffer(this.buffer.networkid, channelName);
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["GameChannel"]
window._kiwi_exports.components.GameChannel = exports.default ? exports.default : exports;
