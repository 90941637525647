"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _default = {
  props: ['network'],
  data: function data() {
    return {};
  },
  computed: {
    list: function list() {
      var users;
      var ignorelist = [];
      users = this.network.users;
      var keys = (0, _keys.default)(users);
      var values = (0, _values.default)(users);

      for (var i = 0; i < keys.length; i += 1) {
        if (values[i].ignore) {
          ignorelist.push(keys[i]);
        }
      }

      return ignorelist || [];
    }
  },
  methods: {
    maybeUpdateList: function maybeUpdateList() {},
    removeIgnore: function removeIgnore(user) {
      this.network.users[user].ignore = false;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["IgnoreList"]
window._kiwi_exports.components.IgnoreList = exports.default ? exports.default : exports;
