"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var Emojis = _interopRequireWildcard(require("@/helpers/Emojis"));

var _default = {
  components: {},
  props: ['buffer', 'network', 'message'],
  data: function data() {
    return {
      totop: false,
      antifloodRemoji: [],
      emojis: Emojis.getAllEmojis(),
      forceLoader: false
    };
  },
  mounted: function mounted() {
    this.getTop();
  },
  computed: {},
  methods: {
    getTop: function getTop() {
      if (window.innerHeight < this.$refs.remojiw.getBoundingClientRect().height + this.$refs.remojiw.getBoundingClientRect().top) {
        this.totop = true;
      } else {
        this.totop = false;
      }
    },
    clickRemoji: function clickRemoji(remoji) {
      if (this.message.tags != null && this.message.tags.msgid != null) {
        this.$parent.remojiWindowOpened = '';

        if (this.message.remojis != null && this.message.remojis.remojis.length >= 20) {
          // todo afficher un message d'alerte
          return;
        }

        var network = this.buffer.getNetwork();
        network.ircClient.addingR.add(this.buffer.name, this.message.tags.msgid, remoji);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ReactionEmoji"]
window._kiwi_exports.components.ReactionEmoji = exports.default ? exports.default : exports;
