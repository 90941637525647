"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _TypingStatusIndicator = _interopRequireDefault(require("./TypingStatusIndicator"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

var methods = {
  props: {},
  stopClick: function stopClick() {
    this.props.dblclick = true;
  },
  openUserBox: function openUserBox(user) {
    (0, _setTimeout2.default)(function (props) {
      if (!props.dblclick) {
        props.nicklist.openUserbox(user);
        props.m().hideNickTooltip();
      } else {
        (0, _setTimeout2.default)(function () {
          props.dblclick = false;
        }, 200);
      }
    }, 200, this.props);
  },
  openNickTooltip: function openNickTooltip(event) {
    var rect = event.target.getBoundingClientRect();
    this.props.network.nicklist_tooltip_displayed = true;
    this.props.network.nicklist_tooltip_data = {
      nick: this.props.user.nick,
      realname: this.props.user.realname,
      x: this.props.nicklist.$el.clientWidth,
      y: rect.top
    };
  },
  hideNickTooltip: function hideNickTooltip() {
    this.props.network.nicklist_tooltip_displayed = false;
    this.props.network.nicklist_tooltip_data = {
      nick: '',
      realname: '',
      x: 0,
      y: -300
    };
  },
  userColour: function userColour() {
    var props = this.props;
    var result = props.user.realname.match(/([0-9]*)?\s?(h|f|M|F|H|U)(.*)/);
    var sex = {
      M: 'Male',
      H: 'Male',
      F: 'Female',
      U: 'Other',
      O: 'Other'
    };
    var r = '';

    if (result && (result.length === 4 || result.length === 5)) {
      var a = result[1] === 'U' ? null : result[1];
      var s = result[2] === 'U' ? null : sex[result[2]];
      var l = result[3] === 'U' ? null : result[3];
      r = {
        a: a,
        s: s,
        l: l
      };
    }

    if (r !== '') {
      if (r.s === 'Male') {
        return 'rgb(136, 193, 255)';
      } else if (r.s === 'Female') {
        return 'rgb(251, 143, 251)';
      }

      return '';
    } else {
      if (props.nicklist.useColouredNicks) {
        return props.user.getColour();
      }

      return '';
    }
  },
  shouldShowAvatars: function shouldShowAvatars() {
    var props = this.props;
    return props.nicklist.buffer.setting('nicklist_avatars');
  }
};
var _default = {
  inject: {
    components: {
      default: {
        AwayStatusIndicator: _AwayStatusIndicator.default,
        TypingStatusIndicator: _TypingStatusIndicator.default,
        Avatar: _Avatar.default
      }
    }
  },
  props: {
    network: Object,
    user: Object,
    nicklist: Object,
    m: {
      default: function m() {
        // vue uses this function to generate the prop. `this`==null Return our own function
        return function n() {
          // Give our methods some props context before its function is called.
          // This is only safe because the function on the methods object is called on
          // the same js tick
          methods.props = this;
          return methods;
        };
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["NicklistUser"]
window._kiwi_exports.components.NicklistUser = exports.default ? exports.default : exports;
