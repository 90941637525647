"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _axios = _interopRequireDefault(require("axios"));

var _default = {
  props: ['ircinput'],
  data: function data() {
    return {
      search: '',
      arrayGifs: []
    };
  },
  computed: {
    gifs: function gifs() {
      var list = {};
      var available = this.$state.setting('gifs');

      _lodash.default.each(available, function (code, ascii) {
        list[code] = ascii;
      });

      return list;
    },
    location: function location() {
      return this.$state.setting('gifLocation');
    }
  },
  methods: {
    addGif: function addGif(url) {
      this.ircinput.insertText(url);
      this.$parent.onToolClickGif();
    },
    formSubmit: function formSubmit() {
      var _this = this;

      _axios.default.get('https://api.giphy.com/v1/gifs/search?api_key=SEyvXa9z8C8xJCYWVf4DQrN8L8GK8gc5&q=' + this.search + '&limit=25&offset=0&rating=G&lang=en').then(function (response) {
        if (response.status === 200) {
          _this.arrayGifs = response.data.data;
        }
      });
    },
    onImgClick: function onImgClick(event) {
      var url = window.getComputedStyle(event.target, null).getPropertyValue('background-image');
      url = Misc.extractURL(url);
      var code = event.target.dataset.code;
      this.ircinput.addImg(code, url);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["Gif"]
window._kiwi_exports.components.inputtools.Gif = exports.default ? exports.default : exports;
