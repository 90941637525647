var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-header kiwi-theme-bg",on:{"click":_vm.onHeaderClick}},[(_vm.isChannel())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_vm._v(_vm._s(_vm.buffer.name))])]),(_vm.isJoined && _vm.isConnected)?_c('div',{key:_vm.buffer.id,staticClass:"kiwi-header-options"},[_vm._l((_vm.pluginUiChannelElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:(plugin.el),expression:"plugin.el"}],key:plugin.id,staticClass:"kiwi-header-option"})}),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkUsers(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Chatteurs")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkChannels(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-hashtag",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Salons")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'ignores'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkIgnores(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-ban",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Ignorés")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'about'
                }},[_c('a',{attrs:{"title":_vm.$t('more_information')},on:{"click":function($event){return _vm.sidebarState.toggleAbout()}}},[_c('i',{staticClass:"fa fa-info",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-nicklist",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'nicklist'
                        || _vm.sidebarSection === 'user'
                }},[_c('a',{attrs:{"title":_vm.$t('person', {count: Object.keys(_vm.buffer.users).length})},on:{"click":function($event){return _vm.sidebarState.toggleNicklist()}}},[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(Object.keys(_vm.buffer.users).length))])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-settings",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'settings'
                }},[_c('a',{attrs:{"title":_vm.$t('channel_settings')},on:{"click":function($event){return _vm.sidebarState.toggleBufferSettings()}}},[_c('i',{staticClass:"fa fa-cog",attrs:{"aria-hidden":"true"}})])]),(_vm.sidebarState.isPinned)?_c('div',{staticClass:"kiwi-header-option kiwi-header-option-unpinsidebar"},[_c('a',{on:{"click":function($event){return _vm.sidebarState.unpin()}}},[_c('i',{staticClass:"fa fa-thumb-tack",attrs:{"aria-hidden":"true"}})])]):_vm._e()],2):_vm._e(),(!_vm.isJoined && _vm.isConnected)?_c('div',{staticClass:"kiwi-header-notjoined"},[_c('a',{staticClass:"u-link kiwi-header-join-channel-button",on:{"click":_vm.joinCurrentBuffer}},[_vm._v(" "+_vm._s(_vm.$t('container_join'))+" ")])]):_vm._e(),_c('transition',{attrs:{"name":"kiwi-header-prompttrans"}},[(_vm.prompts.closeChannel)?_c('input-confirm',{staticClass:"kiwi-header-prompt",attrs:{"label":_vm.$t('prompt_leave_channel'),"flip-connotation":true},on:{"ok":_vm.closeCurrentBuffer,"submit":function($event){_vm.prompts.closeChannel=false}}}):_vm._e()],1)]:(_vm.isServer())?[_vm._m(0),_c('div',{staticClass:"kiwi-header-server-connection"},[(_vm.buffer.getNetwork().state === 'disconnected')?_c('a',{staticClass:"u-button u-button-primary",on:{"click":_vm.onConnectButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('connect'))+" ")]):(_vm.buffer.getNetwork().state === 'connecting')?_c('span',[_c('i',{staticClass:"fa fa-spin fa-spinner",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t('connecting'))+" ")]):_vm._e()]),(_vm.isConnected)?_c('div',{staticClass:"kiwi-header-options"},[_vm._l((_vm.pluginUiChannelElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:(plugin.el),expression:"plugin.el"}],key:plugin.id,staticClass:"kiwi-header-option"})}),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkUsers(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Chatteurs")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkChannels(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-hashtag",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Salons")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'ignores'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkIgnores(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-ban",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Ignorés")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-settings",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'settings'
                }},[_c('a',{attrs:{"title":_vm.$t('channel_settings')},on:{"click":_vm.clickAppSettings}},[_c('i',{staticClass:"fa fa-cog",attrs:{"aria-hidden":"true"}})])])],2):_vm._e()]:(_vm.isQuery())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_c('away-status-indicator',{staticClass:"kiwi-header-awaystatus",attrs:{"network":_vm.buffer.getNetwork(),"user":_vm.network.userByName(_vm.buffer.name)}}),_vm._v(" "+_vm._s(_vm.buffer.name)+" ")],1)]),_c('div',{key:_vm.buffer.id,staticClass:"kiwi-header-options"},[_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkUsers(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Chatteurs")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'channels'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkChannels(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-hashtag",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Salons")])])]),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-about",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'ignores'
                }},[_c('a',{on:{"click":function($event){_vm.showNetworkIgnores(_vm.buffer.getNetwork())}}},[_c('i',{staticClass:"fa fa-ban",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',[_vm._v("Ignorés")])])]),(_vm.userOnline)?_c('div',{staticClass:"kiwi-header-option kiwi-header-option-user",class:{
                    'kiwi-header-option--active': _vm.sidebarSection === 'user'
                }},[_c('a',{on:{"click":function($event){return _vm.toggleUser()}}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}})])]):_vm._e(),_vm._l((_vm.pluginUiQueryElements),function(plugin){return _c('div',{directives:[{name:"rawElement",rawName:"v-rawElement",value:(plugin.el),expression:"plugin.el"}],key:plugin.id,staticClass:"kiwi-header-option"})}),_c('div',{staticClass:"kiwi-header-option kiwi-header-option-leave"},[_c('a',{on:{"click":_vm.closeCurrentBuffer}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])])],2)]:(_vm.isSpecial())?[_c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_vm._v(_vm._s(_vm.buffer.name))])]),_c('div',{staticClass:"kiwi-header-options"},[_c('div',{staticClass:"kiwi-header-option kiwi-header-option-leave"},[_c('a',{on:{"click":_vm.closeCurrentBuffer}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])])])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-header-name-container"},[_c('div',{staticClass:"kiwi-header-name"},[_vm._v(" Andromede ")])])}]

export { render, staticRenderFns }