"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));

var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));

var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));

var _BufferSettings = _interopRequireDefault(require("./BufferSettings"));

var _ChannelInfo = _interopRequireDefault(require("./ChannelInfo"));

var _ChannelBanlist = _interopRequireDefault(require("./ChannelBanlist"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _AppSettings = _interopRequireDefault(require("./AppSettings"));

var _default = {
  components: {
    BufferSettings: _BufferSettings.default,
    ChannelInfo: _ChannelInfo.default,
    ChannelBanlist: _ChannelBanlist.default,
    AwayStatusIndicator: _AwayStatusIndicator.default,
    AppSettings: _AppSettings.default
  },
  props: ['buffer', 'sidebarState'],
  data: function data() {
    return {
      pluginUiChannelElements: _GlobalApi.default.singleton().channelHeaderPlugins,
      pluginUiQueryElements: _GlobalApi.default.singleton().queryHeaderPlugins,
      prompts: {
        closeChannel: false
      }
    };
  },
  computed: {
    isJoined: function isJoined() {
      var buffer = this.buffer;
      return buffer.getNetwork().state === 'connected' && buffer.joined;
    },
    isConnected: function isConnected() {
      return this.buffer.getNetwork().state === 'connected';
    },
    formattedTopic: function formattedTopic() {
      var blocks = (0, _MessageParser.default)(this.buffer.topic, {
        extras: false
      });
      var content = (0, _Html.default)(blocks);
      return content;
    },
    network: function network() {
      return this.buffer.getNetwork();
    },
    sidebarSection: function sidebarSection() {
      return this.sidebarState.section();
    },
    userOnline: function userOnline() {
      var user = this.$state.getUser(this.buffer.getNetwork().id, this.buffer.name);
      return !!user;
    }
  },
  created: function created() {
    var _this = this;

    this.listen(this.$state, 'document.clicked', function (e) {
      // If clicking anywhere else on the page, close all our prompts
      if (!_this.$el.contains(e.target)) {
        var _context;

        (0, _forEach.default)(_context = (0, _keys.default)(_this.prompts)).call(_context, function (prompt) {
          _this.prompts[prompt] = false;
        });
      }
    });
  },
  methods: {
    showNetworkLive: function showNetworkLive(network) {
      this.$state.$emit('active.component', null);
      this.$state.setActiveBuffer(this.buffer.networkid, '#andromede-live');

      if (this.$state.ui.is_narrow) {
        this.$state.$emit('statebrowser.hide');
      }
    },
    showNetworkUsers: function showNetworkUsers(network) {
      network.showServerBuffer('users');
    },
    showNetworkChannels: function showNetworkChannels(network) {
      network.showServerBuffer('channels');
    },
    showNetworkIgnores: function showNetworkIgnores(network) {
      network.showServerBuffer('ignores');
    },
    showPrompt: function showPrompt(prompt) {
      this.prompts[prompt] = true;
    },
    isChannel: function isChannel() {
      return this.buffer.isChannel();
    },
    isServer: function isServer() {
      return this.buffer.isServer();
    },
    isQuery: function isQuery() {
      return this.buffer.isQuery();
    },
    isSpecial: function isSpecial() {
      return this.buffer.isSpecial();
    },
    showNetworkSettings: function showNetworkSettings(network) {
      network.showServerBuffer('settings');
    },
    onConnectButtonClick: function onConnectButtonClick() {
      var network = this.buffer.getNetwork();

      if (!network.connection.server) {
        network.showServerBuffer('settings');
      } else {
        network.ircClient.connect();
      }
    },
    toggleUser: function toggleUser() {
      var user = this.$state.getUser(this.buffer.getNetwork().id, this.buffer.name);
      this.sidebarState.toggleUser(user);
    },
    joinCurrentBuffer: function joinCurrentBuffer() {
      var network = this.buffer.getNetwork();
      this.buffer.enabled = true;
      network.ircClient.join(this.buffer.name);
    },
    closeCurrentBuffer: function closeCurrentBuffer() {
      this.$state.removeBuffer(this.buffer);
    },
    clickAppSettings: function clickAppSettings() {
      this.$state.$emit('active.component.toggle', _AppSettings.default);
    },
    onHeaderClick: function onHeaderClick(event) {
      var channelName = event.target.getAttribute('data-channel-name');

      if (channelName) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ContainerHeader"]
window._kiwi_exports.components.ContainerHeader = exports.default ? exports.default : exports;
