var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"kiwi-nicklist-user",class:[
        _vm.props.nicklist.userMode(_vm.props.user) ?
            'kiwi-nicklist-user--mode-' + _vm.props.nicklist.userMode(_vm.props.user) :
            '',
        _vm.props.user.away ? 'kiwi-nicklist-user--away' : '',
        _vm.props.user.ignore ? 'kiwi-nicklist-user--ignore' : '',
        _vm.data.staticClass ],attrs:{"data-nick":(_vm.props.user.nick||'').toLowerCase()},on:{"click":function($event){_vm.props.m().openUserBox(_vm.props.user)},"dblclick":function($event){_vm.props.m().stopClick();
        _vm.props.nicklist.openQuery(_vm.props.user);
        _vm.props.m().hideNickTooltip()},"mouseover":function($event){_vm.props.m().openNickTooltip($event)},"mouseleave":function($event){_vm.props.m().hideNickTooltip()}}},[(_vm.props.m().shouldShowAvatars())?_c('div',{staticClass:"kiwi-avatar-container"},[(_vm.props.user)?_c(_vm.injections.components.Avatar,{tag:"component",attrs:{"user":_vm.props.user,"size":"small"}}):_vm._e(),_c(_vm.injections.components.AwayStatusIndicator,{tag:"component",attrs:{"network":_vm.props.network,"user":_vm.props.user,"toggle":false}})],1):_c('div',[_c(_vm.injections.components.AwayStatusIndicator,{tag:"component",attrs:{"network":_vm.props.network,"user":_vm.props.user,"toggle":false}})],1),_c('span',{staticClass:"kiwi-nicklist-user-prefix"},[_vm._v(" "+_vm._s(_vm.props.nicklist.userModePrefix(_vm.props.user))+" ")]),_c('span',{staticClass:"kiwi-nicklist-user-nick",style:({ 'color': _vm.props.m().userColour() })},[_vm._v(_vm._s(_vm.props.user.nick)+" ")]),_c('span',{staticClass:"kiwi-nicklist-messageuser",on:{"click":function($event){$event.stopPropagation();return _vm.props.nicklist.openQuery(_vm.props.user)}}},[_c('i',{staticClass:"fa fa-comment",attrs:{"aria-hidden":"true"}})]),_c(_vm.injections.components.TypingStatusIndicator,{tag:"component",attrs:{"user":_vm.props.user,"buffer":_vm.props.nicklist.buffer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }