"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var Vue = require('vue');

var _default = Vue.component('livechannel', {
  components: {},
  props: ['buffer'],
  data: function data() {
    return {
      liveurl: 'https://www.andromede.net',
      self: this
    };
  },
  computed: {},
  methods: {
    toggleInPage: function toggleInPage() {
      if (this.buffer.getAlwaysVisible()) {
        this.buffer.setAlwaysVisible(false);
      } else {
        this.buffer.setAlwaysVisible(true);
      }
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["LiveChannel"]
window._kiwi_exports.components.LiveChannel = exports.default ? exports.default : exports;
