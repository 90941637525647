"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;
var _default = {
  components: {},
  props: ['buffer'],
  computed: {},
  methods: {
    toggleInPage: function toggleInPage() {
      if (this.buffer.getGameAlwaysVisible()) {
        this.buffer.setGameAlwaysVisible(false);
      } else {
        this.buffer.setGameAlwaysVisible(true);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Vladiators"]
window._kiwi_exports.components.Vladiators = exports.default ? exports.default : exports;
