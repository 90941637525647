"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var ipRegex = _interopRequireWildcard(require("ip-regex"));

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var IrcdDiffs = _interopRequireWildcard(require("@/helpers/IrcdDiffs"));

var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));

var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: ['buffer', 'network', 'user'],
  data: function data() {
    return {
      idMessage: '',
      alertMessage: '',
      whoisRequested: false,
      whoisLoading: false,
      alertSended: false,
      showAlertModerator: false
    };
  },
  computed: {
    // Channel modes differ on some IRCds so get them from the network options
    availableChannelModes: function availableChannelModes() {
      var availableModes = [];
      var prefixes = this.network.ircClient.network.options.PREFIX;
      var knownPrefix = {
        q: 'Owner',
        a: 'Admin',
        o: 'Operator',
        h: 'Half-Operator',
        v: 'Voice'
      };

      if (!IrcdDiffs.isAChannelModeAdmin(this.network)) {
        delete knownPrefix.a;
      }

      if (!IrcdDiffs.isQChannelModeOwner(this.network)) {
        delete knownPrefix.q;
      }

      if (!IrcdDiffs.supportsHalfOp(this.network)) {
        delete knownPrefix.h;
      }

      (0, _forEach.default)(prefixes).call(prefixes, function (prefix) {
        var mode = prefix.mode;

        if (knownPrefix[mode]) {
          availableModes.push({
            mode: mode,
            description: knownPrefix[mode]
          });
        }
      });
      return availableModes;
    },
    settingShowPrivateImage: {
      get: function get() {
        var val = this.$state.setting('show_privateimg_' + this.user.nick);

        if (val == null) {
          return true;
        } else {
          return this.$state.setting('show_privateimg_' + this.user.nick);
        }
      },
      set: function set(newVal) {
        return this.$state.setting('show_privateimg_' + this.user.nick, newVal);
      }
    },
    areWeAnOp: function areWeAnOp() {
      if (!this.buffer) {
        return false;
      }

      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    },
    formattedRealname: function formattedRealname() {
      var blocks = (0, _MessageParser.default)(this.user.realname || '', {
        extras: false
      });
      var content = (0, _Html.default)(blocks, false);
      return content;
    },
    isUserOnBuffer: function isUserOnBuffer() {
      if (!this.buffer) {
        return false;
      }

      if (!this.user.buffers[this.buffer.id]) {
        // Probably switched buffer while the userbox was open
        return false;
      }

      return true;
    },
    userMode: {
      get: function getUserMode() {
        if (!this.buffer) {
          return '';
        }

        var userBufferInfo = this.user.buffers[this.buffer.id];

        if (!userBufferInfo) {
          // Probably switched buffer while the userbox was open
          return '';
        }

        var modes = userBufferInfo.modes;
        return modes.length > 0 ? modes[0] : '';
      },
      // Switch the current user mode for the new one
      set: function setUserMode(newVal) {
        var _context;

        var client = this.network.ircClient;
        var oldVal = this.userMode;
        var changes = [];
        var targets = [];

        if (oldVal) {
          changes.push('-' + oldVal);
          targets.push(this.user.nick);
        }

        if (newVal) {
          changes.push('+' + newVal);
          targets.push(this.user.nick);
        }

        var params = (0, _concat.default)(_context = ['MODE', this.buffer.name, changes.join('')]).call(_context, targets);
        client.raw(params);
      }
    },
    userChannels: function userChannels() {
      var _context2;

      var channels = (0, _trim.default)(_context2 = this.user.channels).call(_context2).split(' ');

      for (var i = 0; i < channels.length; i++) {
        channels[i] = TextFormatting.linkifyChannels(channels[i]);
      }

      return channels.join(' ');
    },
    isSelf: function isSelf() {
      return this.user === this.network.currentUser();
    },
    getMyChannel: function getMyChannel() {
      var channels = [];

      for (var i = 0; i < this.network.buffers.length; i++) {
        if (this.network.buffers[i].name.substring(0, 1) === '#') {
          channels.push(this.network.buffers[i].name);
        }
      }

      return channels.join(', ');
    }
  },
  created: function created() {
    var _this = this;

    this.listen(this.$state, 'userbox.alert.show', function (user, id, opts) {
      _this.idMessage = id;
      _this.showAlertModerator = true;
    });
  },
  watch: {
    user: function watchUser() {
      // Reset the whois view since the user is now different
      this.whoisRequested = false;
      this.whoisLoading = false;
    }
  },
  methods: {
    userModeOnThisBuffer: function userModeOnBuffer(user) {
      if (!this.buffer) {
        return '';
      }

      var userBufferInfo = user.buffers[this.buffer.id];
      var modes = userBufferInfo.modes;
      return modes.length > 0 ? modes[0] : '';
    },
    closeInfo: function openQuery() {
      this.$state.$emit('sidebar.show');
    },
    openQuery: function openQuery() {
      var buffer = this.$state.addBuffer(this.network.id, this.user.nick);
      this.$state.setActiveBuffer(this.network.id, buffer.name);

      if (this.$state.ui.is_narrow) {
        this.$state.$emit('userbox.hide');
      } else {
        this.$state.$emit('userbox.hide');
        this.$state.$emit('sidebar.show');
      }
    },
    onChannelsClick: function onChannelsClick(event) {
      var channelName = event.target.getAttribute('data-channel-name');

      if (channelName) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
      }
    },
    toggleAlertModerator: function toggleAlertModerator() {
      this.showAlertModerator = !this.showAlertModerator;
    },
    sendAlertModerator: function sendAlertModerator(channels) {
      var msgs = [];
      /*
      this.network.ircClient.raw('PRIVMSG', '#pole-moderation',
        'Signalement d\'abus. Pseudo: ' + this.user.nick +
        ' - Salon(s): ' + channels + ' - Motif : ' + this.alertMessage
        );
      */

      for (var i = 0; i < this.buffer.messagesObj.messages.length; i++) {
        msgs.push({
          id: this.buffer.messagesObj.messages[i].id,
          nick: this.buffer.messagesObj.messages[i].nick,
          text: this.buffer.messagesObj.messages[i].message,
          time: this.buffer.messagesObj.messages[i].time
        });
      }

      var xhttp = new XMLHttpRequest();
      xhttp.open('POST', 'https://api.team.andromede.net/alert');
      xhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

      xhttp.onload = function () {};

      xhttp.send((0, _stringify.default)({
        who: this.network.nick,
        idmsg: this.idMessage,
        target: this.user.nick,
        reason: this.alertMessage,
        data: msgs
      }));
      this.alertMessage = '';
      this.showAlertModerator = false;
      this.alertSended = true;
    },
    updateWhoisData: function updateWhoisData() {
      var _this2 = this;

      this.whoisRequested = true;
      this.whoisLoading = true;
      this.network.ircClient.whois(this.user.nick, function () {
        _this2.whoisLoading = false;
      });
    },
    kickUser: function kickUser() {
      var reason = prompt('Raison du kick', '');

      if (reason) {
        this.network.ircClient.raw('KICK', this.buffer.name, this.user.nick, reason);
      }
    },
    createBanMask: function createBanMask() {
      // try to ban via user account first
      if (this.user.account) {
        var _context3;

        // if EXTBAN is supported use that
        var extban = IrcdDiffs.extbanAccount(this.network);

        if (extban) {
          return extban + ':' + this.user.account;
        } // if the account name is in the host ban the host
        // Eg. user@network/user/accountname


        if ((0, _indexOf.default)(_context3 = this.user.host.toLowerCase()).call(_context3, this.user.account.toLowerCase()) > -1) {
          return '*!*@' + this.user.host;
        }
      } // if an ip address is in the host and not the whole host ban the ip
      // Eg. user@gateway/1.2.3.4


      var ipTest = new RegExp('(' + ipRegex.v4().source + '|' + ipRegex.v6().source + ')');

      if (ipTest.test(this.user.host)) {
        var match = this.user.host.match(ipTest)[0];

        if (match !== this.user.host) {
          return '*!*@*' + match + '*';
        }
      } // if an 8 char hex is the username ban by username. Commonly used in gateways
      // Eg. 59d4c432@a.clients.kiwiirc.com


      var hexTest = /^([a-f0-9]{8})$/i;

      if (hexTest.test(this.user.username)) {
        var _match = this.user.username.match(hexTest)[0];
        return '*!' + _match + '@*';
      } // fallback to default_ban_mask from config


      var mask = this.$state.setting('buffers.default_ban_mask');
      mask = mask.replace('%n', this.user.nick);
      mask = mask.replace('%i', this.user.username);
      mask = mask.replace('%h', this.user.host);
      return mask;
    },
    banNickUser: function banUser() {
      if (!this.user.username || !this.user.host) {
        return;
      }

      var banMask = this.user.nick + '!*@*';
      this.network.ircClient.raw('MODE', this.buffer.name, '+b', banMask);
    },
    banSilenceUser: function banUser() {
      if (!this.user.username || !this.user.host) {
        return;
      }

      var banMask = 'm:*!*@' + this.user.host;
      this.network.ircClient.raw('TBAN', this.buffer.name, '5m', banMask);
    },
    banUser: function banUser() {
      if (!this.user.username || !this.user.host) {
        return;
      }

      var banMask = this.createBanMask();
      this.network.ircClient.raw('MODE', this.buffer.name, '+b', banMask);
    },
    kickbanUser: function kickbanuser() {
      if (!this.user.username || !this.user.host) {
        return;
      }

      var banMask = this.createBanMask();
      var reason = prompt('Raison du kick', '');

      if (reason) {
        this.network.ircClient.raw('MODE', this.buffer.name, '+b', banMask);
        this.network.ircClient.raw('KICK', this.buffer.name, this.user.nick, reason);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UserBox"]
window._kiwi_exports.components.UserBox = exports.default ? exports.default : exports;
