"use strict";
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _default = {
  components: {},
  props: ['buffer', 'idmsg', 'message'],
  data: function data() {
    return {
      remojis: [],
      remojilist: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    if (this.message != null && this.message.remojis != null) {
      this.remojis = this.message.remojis.remojis;
      this.remojilist = this.message.remojis.remojilist;
    }

    this.$state.$on('messagelist.addRemoji', function (opt) {
      if (opt && opt.id) {
        if (_this.message.tags != null && _this.message.tags.msgid === opt.id) {
          if (opt.status === 'add') {
            var _context;

            if (_this.remojilist[opt.emoji] == null) {
              _this.remojilist[opt.emoji] = {};
            }

            if (_this.remojilist[opt.emoji].list == null) {
              _this.remojilist[opt.emoji].list = [];
            }

            if ((0, _indexOf.default)(_context = _this.remojis).call(_context, opt.emoji) === -1) {
              _this.remojis.push(opt.emoji);
            }

            var test = false;

            for (var i = 0; i < _this.remojilist[opt.emoji].list.length; i++) {
              if (_this.remojilist[opt.emoji].list[i].nick === opt.nick) {
                test = true;
                break;
              }
            }

            if (!test) {
              _this.remojilist[opt.emoji].list.push({
                nick: opt.nick
              });

              _this.message.remojis = {
                remojis: _this.remojis,
                remojilist: _this.remojilist
              };

              _this.$forceUpdate();
            }
          } else if (opt.status === 'remove') {
            if (_this.remojilist[opt.emoji] == null) {
              return;
            }

            if (_this.remojilist[opt.emoji].list == null) {
              return;
            }

            var index = -1;

            for (var _i = 0; _i < _this.remojilist[opt.emoji].list.length; _i++) {
              if (_this.remojilist[opt.emoji].list[_i].nick === opt.nick) {
                index = _i;
                break;
              }
            }

            if (index > -1) {
              var _context2;

              (0, _splice.default)(_context2 = _this.remojilist[opt.emoji].list).call(_context2, index, 1);

              if (_this.remojilist[opt.emoji].list.length === 0) {
                var _context3, _context4;

                (0, _splice.default)(_context3 = _this.remojis).call(_context3, (0, _indexOf.default)(_context4 = _this.remojis).call(_context4, opt.emoji), 1);
              }

              _this.message.remojis = {
                remojis: _this.remojis,
                remojilist: _this.remojilist
              };

              _this.$forceUpdate();
            }
          }
        }
      }
    });
  },
  computed: {},
  methods: {
    clickRemoji: function clickRemoji(remoji) {
      var test = false;
      var network = this.buffer.getNetwork();

      for (var i = 0; i < this.remojilist[remoji].list.length; i++) {
        if (this.remojilist[remoji].list[i].nick === network.nick) {
          test = true;
          break;
        }
      }

      if (!test) {
        network.ircClient.addingR.add(this.buffer.name, this.message.tags.msgid, remoji);
      } else {
        network.ircClient.addingR.remove(this.buffer.name, this.message.tags.msgid, remoji);
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MessageRemoji"]
window._kiwi_exports.components.MessageRemoji = exports.default ? exports.default : exports;
